import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
  path: '/order',
  name: 'order',
  meta: { title: '订单' },
  redirect: '/order/lists',
  component: Main,
  children: [
    {
      path: '/order/lists',
      name: 'lists',
      meta: {
        title: '订单管理',
        parentPath: '/order',
        icon: 'icon-shangchengguanli-weixuanzhong',
        permission: ['view']
      },
      component: Blank,
      redirect: '/order/lists',
      children: [
        {
          path: '/order/lists',
          name: 'order_lists',
          meta: {
            title: '订单列表',
            parentPath: '/order',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/order/order/lists.vue')
        },  {
          path: '/order/lists_1',
          name: 'order_lists_1',
          meta: {
            title: '订单-待推送',
            parentPath: '/order',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/order/order/lists-1.vue')
        },{
          path: '/order/lists_2',
          name: 'order_lists_2',
          meta: {
            title: '订单-待接单',
            parentPath: '/order',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/order/order/lists-2.vue')
        },
        
         {
          path: '/order/lists_3',
          name: 'order_lists_3',
          meta: {
            title: '订单-生产中',
            parentPath: '/order',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/order/order/lists-3.vue')
        }, {
          path: '/order/lists_4',
          name: 'order_lists_4',
          meta: {
            title: '订单-待发货',
            parentPath: '/order',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/order/order/lists-4.vue')
        },{
          path: '/order/lists_5',
          name: 'order_lists_5',
          meta: {
            title: '订单-已完结',
            parentPath: '/order',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/order/order/lists-5.vue')
        },
      ]
    },

  ]
}]

export default routes
