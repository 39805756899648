import Main from "@/layout/main.vue";
const routes = [
  {
    path: "/factory",
    name: "factory",
    meta: { title: "工厂" },
    redirect: "/factory/factory_list",
    component: Main,
    children: [
      {
        path: "/factory/factory_list",
        name: "factory_list",
        meta: {
          title: "工厂列表",
          parentPath: "/factory",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/factory/factory_list.vue"),
      },
      {
        path: "/factory/factory_edit",
        name: "factory_edit",
        meta: {
          hidden: true,
          title: "物流账号编辑",
          parentPath: "/factory",
          prevPath: "/factory/factory_list",
        },
        component: () => import("@/views/factory/factory_edit.vue"),
      },
      {
        path: "/factory/send_template",
        name: "factory_send_template",
        meta: {
          hidden: true,
          title: "发件人模板",
          parentPath: "/factory",
          prevPath: "/factory/factory_list",
        },
        component: () => import("@/views/factory/send_template.vue"),
      },
      {
        path: "/factory/modify_account",
        name: "factory_modify_account",
        meta: {
          hidden: true,
          title: "修改账号",
          parentPath: "/factory",
          prevPath: "/factory/factory_list",
        },
        component: () => import("@/views/factory/modify_account.vue"),
      },
      
    ],
  },
];

export default routes;
