import Main from "@/layout/main.vue";
const routes = [
  {
    path: "/model",
    name: "model",
    meta: { title: "模型" },
    redirect: "/model/model_list",
    component: Main,
    children: [
      {
        path: "/model/model_list",
        name: "model_list",
        meta: {
          title: "模型列表",
          parentPath: "/model",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/model/model_list.vue"),
      },
      {
        path: "/model/model_edit",
        name: "model_edit",
        meta: {
          hidden: true,
          title: "模型编辑",
          parentPath: "/model",
          prevPath: "/model/model_list",
        },
        component: () => import("@/views/model/model_edit.vue"),
      },
    ],
  },
];

export default routes;
