import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [{
  path: '/shop',
  name: 'shop',
  meta: { title: '商城' },
  redirect: '/shop/lists',
  component: Main,
  children: [
    {
      path: '/shop/lists',
      name: 'lists',
      meta: {
        title: '商城管理',
        parentPath: '/shop',
        icon: 'icon-shangchengguanli-weixuanzhong',
        permission: ['view']
      },
      component: Blank,
      redirect: '/shop/lists',
      children: [
        {
          path: '/shop/lists',
          name: 'shop_lists',
          meta: {
            title: '商城列表',
            parentPath: '/shop',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/lists.vue')
        },
        {
          path: '/shop/lists_detail',
          name: 'lists_detail',
          meta: {
            hidden: true,
            title: '商城信息',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/lists_edit.vue')
        },
        {
          path: '/shop/model',
          name: 'shop_model',
          meta: {
            hidden: true,
            title: '商城模型',
            parentPath: '/shop',
            prevPath: '/shop/lists',
          },
          component: () => import('@/views/shop/shop/model.vue')
        },
        {
          path: '/shop/model_edit',
          name: 'shop_model_edit',
          meta: {
            hidden: true,
            title: '商城模型',
            parentPath: '/shop',
            prevPath: '/shop/lists',
          },
          component: () => import('@/views/shop/shop/model_edit.vue')
        },
        {
          path: '/shop/modify_account',
          name: 'modify_account',
          meta: {
            hidden: true,
            title: '修改超级管理员账号',
            parentPath: '/shop',
            prevPath: '/shop/lists',
            icon: 'icon_dianpu_home',
            permission: ['view']
          },
          component: () => import('@/views/shop/shop/modify_account.vue')
        },
      ]
    },
    {
      path: '/shop/set_meal',
      name: 'set_meal',
      meta: {
        title: '套餐管理',
        parentPath: '/shop',
        icon: 'icon-taocanguanli',
        permission: ['view']
      },
      component: Blank,
      redirect: '/shop/set_meal',
      children: [
        {
          path: '/shop/set_meal',
          name: 'set_meal',
          meta: {
            title: '套餐列表',
            parentPath: '/shop',
            permission: ['view']
          },
          component: () => import('@/views/shop/set_meal/lists.vue')
        },
        {
          path: '/shop/set_meal_edit',
          name: 'set_meal_edit',
          meta: {
            hidden: true,
            title: '商城套餐',
            parentPath: '/shop',
            prevPath: '/shop/set_meal',
            permission: ['view']
          },
          component: () => import('@/views/shop/set_meal/edit.vue')
        }
      ]
    },
    
    {
      path: '/shop/order',
      name: 'set_order',
      meta: {
        title: '订单管理',
        parentPath: '/shop',
        icon: 'icon-taocanguanli',
        permission: ['view']
      },
      component: Blank,
      redirect: '/shop/order_lists',
      children: [
        {
          path: '/shop/order_lists',
          name: 'order_lists',
          meta: {
            title: '订单列表',
            parentPath: '/shop',
            permission: ['view']
          },
          component: () => import('@/views/shop/order/orderList.vue')
        },
        {
          path: '/shop/order_list_edit',
          name: 'order_list_edit',
          meta: {
            hidden: true,
            title: '编辑订单',
            parentPath: '/shop',
            prevPath: '/shop/order_list',
            permission: ['view']
          },
          component: () => import('@/views/shop/order/order_detail.vue')
        }, 
        // {
        //   path: '/shop/store_order_lists',
        //   name: 'store_order_lists',
        //   meta: {
        //     title: '商户订单列表',
        //     parentPath: '/shop',
        //     permission: ['view']
        //   },
        //   component: () => import('@/views/shop/order/storeOrderList.vue')
        // },
      ]
    },
    {
      path: '/shop/message',
      name: 'set_message',
      meta: {
        title: '留言管理',
        parentPath: '/shop',
        icon: 'icon-taocanguanli',
        permission: ['view']
      },
      component: Blank,
      redirect: '/shop/message_lists',
      children: [
        {
          path: '/shop/message_lists',
          name: 'message_lists',
          meta: {
            title: '留言管理',
            parentPath: '/shop',
            permission: ['view']
          },
          component: () => import('@/views/shop/message/messageList.vue')
        },
        {
          path: '/shop/message_list_detail',
          name: 'message_list_detail',
          meta: {
            hidden: true,
            title: '留言详情',
            parentPath: '/shop',
            prevPath: '/shop/message_list',
            permission: ['view']
          },
          component: () => import('@/views/shop/message/message_detail.vue')
        }
      ]
    }
  ]
}]

export default routes
