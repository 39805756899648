import Main from "@/layout/main.vue";
const routes = [
  {
    path: "/logistics",
    name: "logistics",
    meta: { title: "物流" },
    redirect: "/logistics/account_list",
    component: Main,
    children: [
      {
        path: "/logistics/account_list",
        name: "logistics_account",
        meta: {
          title: "物流账号",
          parentPath: "/logistics",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/logistics/account_list.vue"),
      },
      {
        path: "/logistics/account_edit",
        name: "logistics_account_edit",
        meta: {
          hidden: true,
          title: "物流账号编辑",
          parentPath: "/logistics",
          prevPath: "/logistics/account_list",
        },
        component: () => import("@/views/logistics/account_edit.vue"),
      },
      {
        path: "/logistics/express_list",
        name: "logistics_express",
        meta: {
          title: "快递列表",
          parentPath: "/logistics",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/logistics/express_list.vue"),
      },
      {
        path: "/logistics/express_edit",
        name: "logistics_express_edit",
        meta: {
          hidden: true,
          title: "快递编辑",
          parentPath: "/logistics",
          prevPath: "/logistics/express_list",
        },
        component: () => import("@/views/logistics/express_edit.vue"),
      },
      {
        path: "/logistics/express_rule",
        name: "logistics_express_rule",
        meta: {
          hidden: true,
          title: "快递规则",
          parentPath: "/logistics",
          prevPath: "/logistics/express_list",
        },
        component: () => import("@/views/logistics/express_rule.vue"),
      },
      {
        path: "/logistics/facesheet_list",
        name: "logistics_facesheet",
        meta: {
          title: "面单模板",
          parentPath: "/logistics",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/logistics/facesheet_list.vue"),
      },
      {
        path: "/logistics/facesheet_edit",
        name: "logistics_facesheet_edit",
        meta: {
          hidden: true,
          title: "面单模板编辑",
          parentPath: "/logistics",
          prevPath: "/logistics/facesheet_edit",
        },
        component: () => import("@/views/logistics/facesheet_edit.vue"),
      },
      {
        path: "/logistics/logistics",
        name: "logistics_set",
        meta: {
          title: "物流设置",
          parentPath: "/logistics",
          icon: "",
          permission: ["view"],
        },
        component: () => import("@/views/logistics/logistics.vue"),
      },
    ],
  },
];

export default routes;
